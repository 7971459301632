import React, { useState } from 'react';
import './BottomNavigation.css';

const BottomNavigation = () => {
  const [active, setActive] = useState('home');

  const handleNavigationClick = (page) => {
    setActive(page);
  };

  return (
    <div className="bottom-navigation fixed-bottom d-flex justify-content-around py-2 border-top" style={{backgroundColor: 'red'}}>
      <button className={`btn btn-link ${active === 'home' ? 'text-primary' : 'text-dark'}`} onClick={() => handleNavigationClick('home')}>
        <i className="bi bi-house-door" style={{ color: 'var(--text-color)' }}></i>
       
      </button>
      
      <button className={`btn btn-link ${active === 'track' ? 'text-primary' : 'text-dark'}`} onClick={() => handleNavigationClick('track')}>
        <i className="bi bi-map" style={{ color: 'var(--text-color)' }}></i>
       
      </button>
      <button className={`btn btn-link ${active === 'menu' ? 'text-primary' : 'text-dark'}`} onClick={() => handleNavigationClick('menu')}>
        <i className="bi bi-list" style={{ color: 'var(--text-color)' }}></i>
       
      </button>
      <button className={`btn btn-link ${active === 'games' ? 'text-primary' : 'text-dark'}`} onClick={() => handleNavigationClick('games')}>
        <i className="bi bi-controller" style={{ color: 'var(--text-color)' }}></i>
       
      </button>
      <button className={`btn btn-link ${active === 'profile' ? 'text-primary' : 'text-dark'}`} onClick={() => handleNavigationClick('profile')}>
        <i className="bi bi-person" style={{ color: 'var(--text-color)' }}></i>
        
      </button>
    </div>
  );
};

export default BottomNavigation;
