import React from 'react';
import './RegistrationPage.css';

const RegistrationPage = () => {
  return (
    <div className="registration-page">
      <h2>Register</h2>
      {/* Add your registration form here */}
    </div>
  );
};

export default RegistrationPage;
