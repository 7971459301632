import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

function FoodDetailModal({ show, onHide, foodDetails }) {
  // State for quantities of individual extras
  const [foodQuantity, setFoodQuantity] = useState(1);
  const [bottleWaterQuantity, setBottleWaterQuantity] = useState(1);
  const [chickenQuantity, setChickenQuantity] = useState(1);
  const [plantainQuantity, setPlantainQuantity] = useState(1);

  // Handlers for increment and decrement for main food
  const incrementFood = () => setFoodQuantity((prev) => prev + 1);
  const decrementFood = () => {
    if (foodQuantity > 1) setFoodQuantity((prev) => prev - 1);
  };

  // Handlers for increment and decrement for each extra item
  const incrementBottleWater = () => setBottleWaterQuantity((prev) => prev + 1);
  const decrementBottleWater = () => {
    if (bottleWaterQuantity > 1) setBottleWaterQuantity((prev) => prev - 1);
  };

  const incrementChicken = () => setChickenQuantity((prev) => prev + 1);
  const decrementChicken = () => {
    if (chickenQuantity > 1) setChickenQuantity((prev) => prev - 1);
  };

  const incrementPlantain = () => setPlantainQuantity((prev) => prev + 1);
  const decrementPlantain = () => {
    if (plantainQuantity > 1) setPlantainQuantity((prev) => prev - 1);
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>{foodDetails.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="food-image-container mb-3">
          <img
            src={foodDetails.imagePath}
            alt={foodDetails.name}
            className="img-fluid"
            style={{ borderRadius: '8px', width: '100%', maxHeight: '300px', objectFit: 'cover' }}
          />
        </div>
        <p>{foodDetails.description}</p>
        <p>Price: ${foodDetails.price}</p>

        {/* Main Food Quantity Controls */}
        <div className="quantity-container d-flex align-items-center justify-content-between mt-3">
          <div className="extra">Quantity</div>
          <Button variant="danger" onClick={decrementFood} disabled={foodQuantity <= 1}>
            -
          </Button>
          <span className="danger" style={{ fontSize: '1.5rem', margin: '0 10px' }}>
            {foodQuantity}
          </span>
          <Button variant="danger" onClick={incrementFood}>
            +
          </Button>
        </div>

        <hr />
        <h5>Extras</h5>

        <div className="quantity-container d-flex align-items-center justify-content-between mt-3">
          <div className="extra">Bottle Water</div>
          <Button variant="danger" onClick={decrementBottleWater} disabled={bottleWaterQuantity <= 1}>
            -
          </Button>
          <span className="danger" style={{ fontSize: '1.5rem', margin: '0 10px' }}>
            {bottleWaterQuantity}
          </span>
          <Button variant="danger" onClick={incrementBottleWater}>
            +
          </Button>
        </div>

        <div className="quantity-container d-flex align-items-center justify-content-between mt-3">
          <div className="extra">Chicken</div>
          <Button variant="danger" onClick={decrementChicken} disabled={chickenQuantity <= 1}>
            -
          </Button>
          <span className="danger" style={{ fontSize: '1.5rem', margin: '0 10px' }}>
            {chickenQuantity}
          </span>
          <Button variant="danger" onClick={incrementChicken}>
            +
          </Button>
        </div>

        <div className="quantity-container d-flex align-items-center justify-content-between mt-3">
          <div className="extra">Plantain</div>
          <Button variant="danger" onClick={decrementPlantain} disabled={plantainQuantity <= 1}>
            -
          </Button>
          <span className="danger" style={{ fontSize: '1.5rem', margin: '0 10px' }}>
            {plantainQuantity}
          </span>
          <Button variant="danger" onClick={incrementPlantain}>
            +
          </Button>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger">Add to cart</Button>
        <Button variant="secondary" onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default FoodDetailModal;
