import React from 'react';
import './Dashboard.css';
import HomePage from './HomePage';
import MenuPage from './MenuPage';
import TrackPage from './TrackPage';
import GamesPage from './GamesPage';
import ProfilePage from './ProfilePage';
import BottomNavigation from '../../components/BottomNavigation/BottomNavigation';

const Dashboard = () => {
  return (
    <div className="dashboard">
      <HomePage /> {/* Replace this with routing logic if needed */}
      <BottomNavigation />
    </div>
  );
};

export default Dashboard;
