import React, { useState, useEffect } from 'react';
import './HomePage.css';
import FoodDetailModal from '../../components/FoodDetailModal';
const HomePage = () => {
  const [modalShow, setModalShow] = useState(false);
  const [foodDetails, setFoodDetails] = useState({
    name: '',
    imagePath: '',
    description: ''
  });

  const handleShowModal = (name, imagePath, description, price) => {
    setFoodDetails({ name, imagePath, description, price });
    setModalShow(true);
  };

  // useEffect(() => {
  //   const makeFullScreen = () => {
  //     const element = document.documentElement; // Select the entire document
  //     if (element.requestFullscreen) {
  //       element.requestFullscreen();
  //     } else if (element.mozRequestFullScreen) { // Firefox
  //       element.mozRequestFullScreen();
  //     } else if (element.webkitRequestFullscreen) { // Chrome, Safari, and Opera
  //       element.webkitRequestFullscreen();
  //     } else if (element.msRequestFullscreen) { // IE/Edge
  //       element.msRequestFullscreen();
  //     }
  //   };

  //   makeFullScreen(); // Call the function on component mount
  // }, []); // Empty dependency array to run only on mount

  const cards = [
    {
      imgSrc: 'https://www.summahealth.org/-/media/project/summahealth/website/page-content/flourish/2_18a_fl_fastfood_400x400.webp?la=en&h=400&w=400&hash=145DC0CF6234A159261389F18A36742A',
      description: 'Pink flowers',
      name: 'Semo and Egusi',
      price: 'N800',
    },
    {
      imgSrc: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTwWLNiHBcLq3dqVBE0ivhrVAOylc-Kg2jyJx4_tnJ21PJ4mXDhOjP_akkpL-FFTEm-IbY&usqp=CAU',
      description: 'Cool image',
      name: 'Semo and Egusi',
      price: 'N800',
    },
    {
      imgSrc: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/3a/Egusi_soup_with_pounded_yam_and_assorted_meats.jpg/220px-Egusi_soup_with_pounded_yam_and_assorted_meats.jpg',
      description: 'Cool image',
      name: 'Semo and Egusi',
      price: 'N800',
    },
    {
      imgSrc: 'https://cdn.shopify.com/s/files/1/0067/1576/8885/files/R_2_2c67cc4f-67c6-45e0-bf97-b9af1791d7af_1024x1024.jpg?v=1710256814',
      description: 'Pink flowers',
      name: 'Semo and Egusi',
      price: 'N800',
    },
    {
      imgSrc: 'https://cdn.shopify.com/s/files/1/0067/1576/8885/files/nigerian-wedding-dishes-how-to-choose-the-best-dishes-for-your-reception-party_1024x1024.jpg?v=1710256684',
      description: 'Cool image',
      name: 'Semo and Egusi',
      price: 'N2000',
    },
    {
      imgSrc: 'https://cdn.shopify.com/s/files/1/0067/1576/8885/files/yam-pottage-2-1024x688_1024x1024.jpg?v=1710257247',
      description: 'Cool image',
      name: 'Semo and Egusi',
      price: 'N800',
    },
    {
      imgSrc: 'https://cdn.shopify.com/s/files/1/0067/1576/8885/files/R_3_5b98feb6-bd1e-4e88-bdc5-960717273de1_1024x1024.jpg?v=1710257748',
      description: 'Pink flowers',
      name: 'Semo and Egusi',
      price: 'N1400',
    },
    {
      imgSrc: 'https://cdn.shopify.com/s/files/1/0067/1576/8885/files/IMG_2364_1024x1024.jpg?v=1710261697',
      description: 'Cool image',
      name: 'Semo and Egusi',
      price: 'N800',
    },
    {
      imgSrc: 'https://i.pinimg.com/564x/61/52/f1/6152f161406f4a62e31ff1cf4611a385.jpg',
      description: 'Cool image',
      name: 'Semo and Egusi',
      price: 'N700',
    },
    {
      imgSrc: 'https://i.pinimg.com/564x/d3/74/2a/d3742a8604644dff2263c90afb775c5e.jpg',
      description: 'Cool image',
      name: 'Semo and Egusi',
      price: 'N500',
    },
    {
      imgSrc: 'https://i.pinimg.com/564x/e0/53/8b/e0538ba48835c05d5b54c43d71beea98.jpg',
      description: 'Cool image',
      name: 'Semo and Egusi',
      price: 'N700',
    },
    {
      imgSrc: 'https://i.pinimg.com/564x/47/57/79/475779646369f0b41456833ede0b6537.jpg',
      description: 'Cool image',
      name: 'Semo and Egusi',
      price: 'N800',
    },
  ];

  const [isGold, setIsGold] = useState(false); // State to manage the theme

  const toggleTheme = () => {
    setIsGold(prev => !prev); // Toggle the theme
  };

  return (
    <>
      <div className="header">
        <div className="header-summary">
          <div className="summary-text">
            My Balance
          </div>
          <div className="summary-balance">
            N 293.00
          </div>
          <div className="summary-text-2">
            +&nbsp;280.00
          </div>
        </div>
        <div className="user-profile">
          <img src="https://i.pinimg.com/564x/29/55/59/295559e87b67fde4bbd5d5049d67e678.jpg" class="user-photo" />
        </div>
      </div>
      <div className={`switch-mode ${isGold ? 'gold' : 'silver'}`}>
        <label className="switch">
          <input type="checkbox" checked={isGold} onChange={toggleTheme} />
          <span className="slider"></span>
        </label>
        <span>{isGold ? 'Gold' : 'Silver'} Mode</span>
      </div>


      <div className="container" >

        {cards.map((card, index) => (
          <div className="content-wrapper" key={index}>
            <div className="image-wrapper">
              <div className="overlay">
                <div>
                  <button className="save" onClick={() => handleShowModal(card.name, card.imgSrc, card.description, card.price)}
                  >Pick</button>
                </div>
                <div>
                  <button className="spheric-button">{card.price}</button>

                </div>
              </div>
              <img src={card.imgSrc} alt={card.description} />
            </div>

          </div>

        ))}
      </div>
      <FoodDetailModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        foodDetails={foodDetails}
      />
    </>
  );
};

export default HomePage;
